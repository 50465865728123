@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Pretendard Variable', Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
    'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
  color: #000000;
  letter-spacing: -0.004em;
}

html {
  height: -webkit-fill-available;
}
.height-full {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}


a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

.inner {
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
}

textarea,
input {
  outline: none;
}

textarea:focus,
input:focus {
  outline: none;
}

.bg-transparent {
  background-color: transparent;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-touch-callout: none;
}


.wrapper {
  max-width: 100%;
  overflow: hidden;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  animation: marquee 15s linear infinite;
}

.marquee p {
  display: inline-block;
}

@keyframes marquee {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
